import React from 'react'
import partners12 from './partners12.jpg'
export default function Footer() {
  return (
  <>

  <img src={partners12} className='img-fluid partners'/>
      <section className="footer mnone">
<div className="footermenu">
 <ul className="d-flex">
  <li>About Us


</li>
  <li>Matches </li>
  <li>Standings </li>
  <li>Squad</li>
  <li>News</li>
  <li>Auction</li>


 </ul>
</div>

      </section>
      
<div className="footercpoy">
  <p>&copy; 2024 Kashikings. All rights reserved.</p>
</div></>
  )
}
