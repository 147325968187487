import React from 'react'
import Preheader from './Preheader'
import Table from './Table'
import Parters from './Parters'
import Footer from './Footer'
import { Helmet } from 'react-helmet'
export default function Standing() {
  return (
  <>

<Helmet>
                <meta charSet="utf-8" />
                <title> Kashi Kings Standings - Latest Kabaddi League Rankings



                </title>
                <meta name="description" content=" Stay updated with the latest standings of Kashi Kings in the Kabaddi league. Check out our current rankings, points, and performance stats. Follow our journey as we compete for the top spot in the league and cheer for Kashi Kings in every match.

"/>
                <meta name="keywords" content="
Kashi kings standings
Kabaddi League Rankings
Current Rankings of Kashi kings"/>
                <link rel="canonical" href="https://kashikings.com/standing" />
            </Helmet>


  <Preheader/>
   <div class="container-fluid p-0">
        <div class="breadcrumb-bg">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <div className="newheading1">
  <h5>Standings</h5>
  <div className="line"></div>
</div>          
                </ol>
            </nav>
        </div>
        </div>

        <Table/>
        <Parters/>
        <Footer/>
  </>
  )
}
