import React from 'react'
import p1 from './fancode-01.jpg'
import p2 from './image/somy.png'
import partners12 from './partners12.jpg'
import p3 from './image/dd sports (1).png'
import p4 from './image/redfm.png'
import p5 from './image/scary-fox-coloured (1).jpg'
import p6 from './emt-white-logo-with-coloured-bg-02.jpg'
import p7 from'./co-power.jpg'



export default function Parters() {
  return (
   <>

{/* <img src={partners12} className='img-fluid'/> */}


    <section className="partners">
    {/* <div className="newheading">
                    <h5>Media Patners</h5>
                </div> */}
<div className="newheading1">
  <h5>Partners</h5>
  <div className="line"></div>
</div>          
        <div className="container">
          <div className="row">
            {/* <div className="col-md-2 col-6">
              <div className="sponsor">
               <h5>Jio Cinema</h5>
               <img src={p1} className="img-fluid sponsor"/>
              </div>
            </div> */}
              <div className="col-md-2 col-6">
              <div className="sponsor">
               <h5>CO-POWERED by</h5>
               <img src={p7} className="img-fluid sponsor"/>
              </div>
            </div>

            <div className="col-md-2 col-6">
              <div className="sponsor">
               <h5>BROADCAST PARTNER</h5>
               <img src={p2} className="img-fluid sponsor"/>
              </div>
            </div>

           


            <div className="col-md-2 col-6">
              <div className="sponsor">
               <h5>RADIO PARTNER</h5>
               <img src={p4} className="img-fluid sponsor"/>
              </div>
            </div>


            <div className="col-md-2 col-6">
              <div className="sponsor">
               <h5>STYLING PARTNER</h5>
               <img src={p5} className="img-fluid sponsor"/>
              </div>
            </div>


            <div className="col-md-2 col-6">
              <div className="sponsor">
               <h5>TRAVEL PARTNER</h5>
               <img src={p6} className="img-fluid sponsor"/>
              </div>
            </div>

            {/* <div className="col-md-2 col-6">
              <div className="sponsor">
               <h5>OTT PARTNER</h5>
               <img src={p1} className="img-fluid sponsor"/>
              </div>
            </div> */}
          </div>
        </div>
      </section></>
  )
}
