// import React from 'react';

import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./image/logo.svg";
import fb from "./image/L1 (1).svg";
import inst from "./image/L1 (2).svg";
import Ln from "./image/L1 (3).svg";
import banner from "./image/bannerimage.svg";
import sony from "./image/sony.svg";
import news from "./image/news.svg";
import logovs from "./image/logovs.svg";
import New from "./New";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import Preheader from "./Preheader";
import Table from "./Table";
import Parters from "./Parters";
import Footer from "./Footer";
import Fixture from "./Fixture";
import teamplayer from "./image/teamplayer.png";
import banner1 from "./kashibanner.jpg";
// import partner from './image/sponsor.png's
import banner2 from './semi final webside.jpg';
import { Helmet } from "react-helmet";

function Home() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/Standings?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        const sortedData = data.data.sort((a, b) => a.position - b.position);

        setStandings(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  const options5 = {
    loop: true,
    margin: 10,
    center: true,
    items: 3,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  const options = {
    loop: true,
    margin: 20,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    dots: false,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Kashi Kings - Official website - Kashi Kings</title>
        <meta
          name="description"
          content="Explore the official website of Kashi Kings, where Kabaddi passion meets excellence. Stay updated with team news, match schedules, player profiles, and more. Join the excitement and support your favorite Kabaddi team in Kashi!
"
        />
        <meta
          name="keywords"
          content="kashi kings
sony sports
Official website
Kashi Kings Kabaddi team"
        />
        <link rel="canonical" href="https://kashikings.com/" />
      </Helmet>

      <Preheader />

      <div className="bannerhome">
        <img src={banner2} className="img-fluid" />
      </div>

      <Fixture />

      <section className="standingtable">
        <div class="container-fluid">
          <div className="newheading1">
            <h5>Standings</h5>
            <div className="line"></div>
          </div>
          <div class="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-dark">
                <tr>
                  <th className="text-center">SI NO.</th>
                  <th></th>
                  <th>TEAMS NAME</th>
                  <th>P</th>
                  <th>W</th>
                  <th>L</th>
                  <th className="mobilenone">T</th>
                  <th className="mobilenone">+/-</th>
                  <th className="mobilenone">PTS</th>
                  <th className="text-center mobilenone">FORM</th>
                </tr>
              </thead>

              <tbody>
                {standings.length > 0 ? (
                  standings.map((team, index) => (
                    <tr
                      key={index}
                      className={team.team === "KASHI KINGS" ? "highlight" : ""}
                    >
                      <td className="text-center">{index + 1}</td>
                      <td>
                        <img
                          src={`https://dev-upkl.upkl.in/${team.image}`}
                          alt={team.team}
                        />
                      </td>{" "}
                      <td>{team.team}</td>
                      <td>{team.matches ? ` ${team.matches} ` : "-"}</td>
                      <td>{team.won ? ` ${team.won} ` : "-"}</td>
                      <td>{team.lost ? ` ${team.lost} ` : "-"}</td>
                      <td className="mobilenone">
                        {team.tied ? ` ${team.tied} ` : "-"}
                      </td>
                      <td className="mobilenone">
                        {" "}
                        {team.score_diff ? ` ${team.score_diff} ` : "-"}
                      </td>
                      <td className="mobilenone">
                        {" "}
                        {team.points ? ` ${team.points} ` : "-"}
                      </td>
                      <td className="mobilenone">
                        <ul>
                          <li
                            className={
                              team.f1 === "W"
                                ? "win"
                                : team.f1 === "L"
                                ? "loss"
                                : team.f1 === "tie-not" || !team.f1
                                ? "T"
                                : "Tie"
                            }
                          >
                            {team.f1}
                          </li>
                          <li
                            className={
                              team.f2 === "W"
                                ? "win"
                                : team.f2 === "L"
                                ? "loss"
                                : team.f2 === "tie-not" || !team.f2
                                ? "T"
                                : "Tie"
                            }
                          >
                            {team.f2}
                          </li>
                          <li
                            className={
                              team.f3 === "W"
                                ? "win"
                                : team.f3 === "L"
                                ? "loss"
                                : team.f3 === "tie-not" || !team.f3
                                ? "T"
                                : "Tie"
                            }
                          >
                            {team.f3}
                          </li>
                          <li
                            className={
                              team.f4 === "W"
                                ? "win"
                                : team.f4 === "L"
                                ? "loss"
                                : team.f4 === "tie-not" || !team.f4
                                ? "T"
                                : "Tie"
                            }
                          >
                            {team.f4}
                          </li>
                          <li
                            className={
                              team.f5 === "W"
                                ? "win"
                                : team.f5 === "L"
                                ? "loss"
                                : team.f5 === "tie-not" || !team.f5
                                ? "T"
                                : "Tie"
                            }
                          >
                            {team.f5}
                          </li>
                        </ul>
                      </td>
                      {/* Render other data accordingly */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">Loading...</td>
                    <td colSpan="3"></td>
                    <td className="mobilenone"></td>
                    <td className="mobilenone"></td>
                    <td className="mobilenone"></td>
                    <td className="mobilenone"></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className="socialplatform">
        <div className="socialmediamargin">
          <iframe
            src="https://widget.taggbox.com/156820"
            style={{ width: "100%", height: "580px", border: "none" }}
          ></iframe>
        </div>
      </section>

      <New />

      <Parters />
      <Footer />
    </>
  );
}

export default Home;
