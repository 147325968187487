import React from 'react'
import New from './New'
import Preheader from './Preheader'
import Parters from './Parters'
import Footer from './Footer'
import news1 from './image/news4.jpg'
import news2 from './image/news2.jpg'
import news3 from './image/news1.jpg'
import { Link } from 'react-router-dom';


export default function Newpage() {
  return (
   <>
   
   <Preheader/>
   <div class="container-fluid p-0">
        <div class="breadcrumb-bg">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <div className="newheading1">
  <h5>News</h5>
  <div className="line"></div>
</div>      
                </ol>
            </nav>
        </div>
        </div>

        <section className="newmedia">
            <div className="row">
              <div className="col-md-3 col-12">
                <div className="media-card">
                  <div class="card">
                    <img src={news2} alt="Card Image" />
                    <div class="overlay">
                      <Link
                        to="https://www.latestly.com/agency-news/sports-news-kashi-kings-unleashing-spirit-of-varanasi-in-up-kabaddi-league-6013340.html/amp"
                        target="_blank"
                      >
                        <h2 class="title">
                          Sports News | Kashi Kings: Unleashing Spirit...
                        </h2>
                        <p class="date">June 12, 2024</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-12">
                <div className="media-card">
                  <div class="card">
                    <img src={news1} alt="Card Image" />
                    <div class="overlay">
                      <Link
                        to="https://www.aninews.in/news/sports/others/kashi-kings-unleashing-spirit-of-varanasi-in-up-kabaddi-league20240604193153/"
                        target="_blank"
                      >
                        <h2 class="title">
                          Kashi Kings: Unleashing spirit of Varanasi in UP
                          Kabaddi League
                        </h2>
                        <p class="date">June 12, 2024</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="media-card">
                  <div class="card">
                    <img src={news3} alt="Card Image" />
                    <div class="overlay">
                      <Link
                        to="https://theprint.in/ani-press-releases/narendra-kumar-yadav-irs-first-civil-servant-appointed-as-brand-ambassador-for-fit-india-movement/1957844/"
                        target="_blank"
                      >
                        <h2 class="title">
                          Narendra Kumar Yadav, IRS first civil servant ...
                        </h2>
                        <p class="date">June 12, 2024</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


        <Parters/>
        <Footer/>

   
   
   </>
  )
}
