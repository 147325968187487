import React from 'react'
import Preheader from './Preheader'
import Parters from './Parters'
import Footer from './Footer'
import player1 from './playerimg/harendra kumar.jpg'
import player2 from './playerimg/sushant chaudhary.jpg'
import player3 from './playerimg/uday dabas (1).jpg'
import player4 from './playerimg/vikash chaudhary.jpg'

import player5 from './playerimg/vikram pratap.jpg'
import player6 from './playerimg/rinku yadav.jpg'
import player7 from './playerimg/lavish chaudhary.jpg'
import player8 from './playerimg/lalit chaudhary.jpg'
import player9 from './playerimg/sumit gupta.jpg'
import player10 from './playerimg/aniruddh.jpg'
import player11 from './playerimg/ravi kumar.jpg'
import player12 from './playerimg/abhinav tomar.jpg'
import { Helmet } from 'react-helmet'
import team1 from './playerimg/harendra kumar.jpg'

export default function Squad() {
  return (
   <>
    <Helmet>
                <meta charSet="utf-8" />
                <title> Kashi Kings Players - Meet the Raiders and Stars of Our Team


                </title>
                <meta name="description" content="Discover the players of Kashi Kings, including the names and roles of our top Kabaddi stars. Learn about Kashi Kings' left raiders, right raiders, and other key players who bring excitement to every game. Explore the full squad lineup and player profiles here.
"/>
                <meta name="keywords" content="ngs.com/about-us

kashikings.com
 	
kashi kings players
kashi kings players name
players of Kashi kings
kashi kings left raiders
kashi kings right raiders"/>
                <link rel="canonical" href="https://kashikings.com/squad" />
            </Helmet>
     <Preheader/>
   <div class="container-fluid p-0">
        <div class="breadcrumb-bg">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <div className="newheading1">
  <h5>Squad</h5>
  <div className="line"></div>
</div>          
                </ol>
            </nav>
        </div>
        </div>
<section className='playersquad'>
    
<div className='container-fluid'>
      <div className='row'>
        <div className='col-md-3 col-12'>
            <img src={player1} className='img-fluid'/>
        </div>

        <div className='col-md-3 col-12'>
            <img src={player2} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player3} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player4} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player5} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player6} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player7} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player8} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player9} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player10} className='img-fluid'/>
        </div>

        <div className='col-md-3 col-12'>
            <img src={player11} className='img-fluid'/>
        </div>


        <div className='col-md-3 col-12'>
            <img src={player12} className='img-fluid'/>
        </div>
      </div>
        </div>
</section>

        <Parters/>
        <Footer/>
   
   </>
  )
}
