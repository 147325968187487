import React, { useEffect } from 'react';
import news from './image/news.svg'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import $ from 'jquery';
import news1 from './image/news4.jpg'
import news2 from './image/news2.jpg'
import news3 from './image/news1.jpg'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
export default function New() {

    // useEffect(() => {
    //     const owl = $('.owl-carousel');
    
    //     owl.on('changed.owl.carousel', function(event) {
    //       const items = $('.owl-item', owl);
    //       items.removeClass('center non-center');
    //       items.each(function() {
    //         if ($(this).hasClass('active')) {
    //           $(this).addClass('center');
    //         } else {
    //           $(this).addClass('non-center');
    //         }
    //       });
    //     });
    //   }, []);

      
    

    const options = {
        items: 5,
        loop: true,
        margin: 20,
        nav: false,
        dots:false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        responsive: {
          0: {
            items: 1.2
          },
          600: {
            items: 3
          },
          1000: {
            items: 3.6
          }

        }
      };

      const options3 = {
        items: 3,
        loop: true,
        margin: 20,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        responsive: {
          0: {
            items:1.3
          },
          600: {
            items: 3
          },
          1000: {
            items: 3
          }
        }
      };
  return (
 <>
 <Helmet>
                <title>Kashi Kings News & Updates - Stay Informed





                </title>
                <meta name="description" content="Keep up with the latest news, updates, and announcements from Kashi Kings. From match results to player interviews, get all the information you need about your favorite Kabaddi team right here.
"/>
                <meta name="keywords" content="kashi kings
UP kabaddi league
kabaddi tournaments up
UPKL
Kulwant baliyan"/>
                <link rel="canonical" href="https://kashikings.com/standing" />
            </Helmet>

 <section className="newmedia">
  <div className="newheadingmedia">
    <h5>News & Media</h5>
    <div className='line2'></div>
    </div>

    <OwlCarousel className="owl-theme" {...options}>
    <div className="media-card">
     <div class="card">
        <img src={news2} alt="Card Image"/>
        <div class="overlay">
        <Link to="https://www.latestly.com/agency-news/sports-news-kashi-kings-unleashing-spirit-of-varanasi-in-up-kabaddi-league-6013340.html/amp" target="_blank">

            <h2 class="title">Sports News | Kashi Kings: Unleashing Spirit...</h2>
            <p class="date">June 12, 2024</p>
            </Link>
        </div>
    </div>
      </div>

      <div className="media-card">
     <div class="card">
        <img src={news1} alt="Card Image"/>
        <div class="overlay">
        <Link to="https://www.aninews.in/news/sports/others/kashi-kings-unleashing-spirit-of-varanasi-in-up-kabaddi-league20240604193153/" target="_blank">

            <h2 class="title">Kashi Kings: Unleashing spirit of Varanasi in UP Kabaddi League</h2>
            <p class="date">June 12, 2024</p>
            </Link>
        </div>
    </div>
      </div>



      <div className="media-card">
     <div class="card">
        <img src={news3} alt="Card Image"/>
        <div class="overlay">
            <Link to="https://theprint.in/ani-press-releases/narendra-kumar-yadav-irs-first-civil-servant-appointed-as-brand-ambassador-for-fit-india-movement/1957844/" target="_blank">
            <h2 class="title">Narendra Kumar Yadav, IRS first civil servant ...</h2>
            <p class="date">June 12, 2024</p>
            </Link>
        </div>
    </div>
      </div>


      
    </OwlCarousel>

   




</section>
{/* 
<section className='newsecond'>
<OwlCarousel className="owl-theme1"  {...options3}>
    <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>

      <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>


      <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>
      <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>


      <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>
    </OwlCarousel>
    
    </section> */}
    </>
  )
}
