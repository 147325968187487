import React from 'react'
import Preheader from './Preheader'
import Parters from './Parters'
import Footer from './Footer'
import founder from './image/founder-img.jpg'
import team1 from './image/t1.jpg'
import team2 from './image/t2.jpg'
import team3 from './vineet-chaudhary.png'
import team4 from './image/t41.png'
import team5 from './yukti-rathi-02.png'
import { Helmet } from 'react-helmet'
import aboutus from './image/abotus.jpg'

export default function About() {
  return (
   <>
   <Helmet>
                <meta charSet="utf-8" />
                <title> About Kashi Kings - Founded by Kulvant Baliyan, A Legacy of Kabaddi in Kashi

                </title>
                <meta name="description" content="Discover the story of Kashi Kings, a premier Kabaddi team based in the vibrant city of Kashi. With a massive and enthusiastic fan base from UP State's 220 million people, Kashi Kings celebrate the sport with top-notch talent and thrilling games. Learn more about our journey, our values, and our commitment to Kabaddi."/>
                <meta name="keywords" content="founder of kashi kings,kulvant baliyan,kabaddi team,kashi kings
"/>
                <link rel="canonical" href="https://kashikings.com/" />
            </Helmet>

     <Preheader/>
   <div class="container-fluid p-0">
        <div class="breadcrumb-bg">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <div className="newheading1">
  <h5>About Us</h5>
  <div className="line"></div>
</div>                </ol>
            </nav>
        </div>
        </div>

        <section className='about--us'>
        <div className='container-fluid'>
            <div className='abou-us'>
              <div className='row'>
              <div className='col-md-5 col-12'>
                    <div className='leftimage'>
                        <img src={aboutus} className='img-fluid'/>
                    </div>
                </div>

                <div className='col-md-7 col-12 align-self-center'>
                    <div className='rigthabout'>
                        <h5>About Kashi Kings</h5>
                       <p>Kashi is a big city with a huge and diverse population, making it a great place for Kabaddi. With over 220 million people in UP State, Kashi is more than just a city. It's a center filled with Kabaddi fans. This large number of people gives the Kashi Kings a wide and passionate fan base, which brings a lively atmosphere to their games. The team is more than just players; it's a celebration of the sport. The mix of excited fans and top-notch Kabaddi talent makes Kashi Kings a leading stage in the Kabaddi league.</p>
                    </div>
                </div>
              </div>
            </div>
        </div>
        </section>

        <section className='kashifounder'>
        <div className="newheading">
                    <h5>Founder Message</h5>
                    <div className='line'></div>
                </div>
       <div className='container-fluid'>
        
    
       <div className='founder'>
            <div className='row'>
                <div className='col-md-4 col-12 align-self-center'>
                    <img src={founder} className='img-fluid'/>
                </div>
                <div className='col-md-8 col-12 align-self-center'>
                    <div className='founderpara'>
                        <p>Kulvant Baliyan , an entrepreneurial luminary, is the visionary behind Shiv Shakti Infra, Adi Yogi Manpower India Pvt Ltd, and Kashi Kings. Renowned for his multifaceted expertise, he excels as a sports enthusiast, esteemed life coach, captivating speaker, and celebrated celebrity. His diverse ventures reflect his unwavering commitment to excellence and innovation, inspiring countless individuals worldwide.</p>
                        <br></br><div className='postion'>
                            <h6>Mr KULVANT BALIYAN</h6>
                            <p>Founder | Kashi Kings</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
        </section>

        <section className='teammember'>
                <div className='container-fluid'>

                    <div className='aboutheading'>
                        <h5>Our Team </h5>
                    </div>
            <div className='row'>

                    <div className='col-md-4  col-12'>
                        <img src={team1}  className='img-fluid'/>
                    </div>
                    <div className='col-md-4  col-12'>
                        <img src={team2}  className='img-fluid'/>
                    </div>
                    <div className='col-md-4  col-12'>
                        <img src={team3}  className='img-fluid'/>
                    </div>
                    <div className='col-md-4  col-12'>
                        <img src={team4}  className='img-fluid'/>
                    </div>

                    <div className='col-md-4  col-12'>
                        <img src={team5}  className='img-fluid'/>
                    </div>
                </div>
            </div>
        </section>

        <Parters/>
        <Footer/>
   
   </>
  )
}
