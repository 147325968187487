import React, { useState, useEffect } from "react";


export default function Table() {

    const [standings, setStandings] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchStandings = async () => {
        try {
          const response = await fetch(
            'https://dev-upkl.upkl.in/api/resource/Standings?fields=["*"]'
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
  
          const sortedData = data.data.sort((a, b) => a.position - b.position);
  
          setStandings(sortedData);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
  
      fetchStandings();
    }, []); 


  return (
    <section className='standingtable'>
    <div class="container-fluid">
    
      <div class="table-responsive">
      <table className="table table-bordered table-hover">
  <thead className="table-dark">
    <tr>
      <th className="text-center">SI NO.</th>
      <th></th>
      <th>TEAMS NAME</th>
      <th>P</th>
      <th>W</th>
      <th>L</th>
      <th className="mobilenone">T</th>
      <th className="mobilenone">+/-</th>
      <th className="mobilenone">PTS</th>
      <th className="text-center mobilenone">FORM</th>
    </tr>
  </thead>
 

  <tbody>
                  {standings.length > 0 ? (
                    standings.map((team, index) => (
                      <tr key={index} className={team.team === "KASHI KINGS" ? "highlight" : ""}>
                        
                        <td className="text-center">{index + 1}</td>
                        <td>
                          <img
                            src={`https://dev-upkl.upkl.in/${team.image}`}
                            alt={team.team}
                          />
                        </td>{" "}
                        <td>{team.team}</td>
                        <td>
                        {team.matches ? ` ${team.matches} ` : '-'}

                        </td>
                        <td>
                        {team.won ? ` ${team.won} ` : '-'}
                        </td>
                        <td>
                        {team.lost ? ` ${team.lost} ` : '-'}
                        </td>
                        <td className="mobilenone">
                        {team.tied ? ` ${team.tied} ` : '-'}
                         </td>
                        <td className="mobilenone">  {team.score_diff ? ` ${team.score_diff} ` : '-'}</td>
                        <td className="mobilenone">   {team.points ? ` ${team.points} ` : '-'}</td>
                        <td className="mobilenone">
                        <ul>
                            <li className={team.f1 === "W" ? "win" : team.f1 === "L" ? "loss" : team.f1 === "tie-not" || !team.f1 ? "T" : "Tie"}>{team.f1}</li>
                            <li className={team.f2 === "W" ? "win" : team.f2 === "L" ? "loss" : team.f2 === "tie-not" || !team.f2 ? "T" : "Tie"}>{team.f2}</li>
                            <li className={team.f3 === "W" ? "win" : team.f3 === "L" ? "loss" : team.f3 === "tie-not" || !team.f3 ? "T" : "Tie"}>{team.f3}</li>
                            <li className={team.f4 === "W" ? "win" : team.f4 === "L" ? "loss" : team.f4 === "tie-not" || !team.f4 ? "T" : "Tie"}>{team.f4}</li>
                            <li className={team.f5 === "W" ? "win" : team.f5 === "L" ? "loss" : team.f5 === "tie-not" || !team.f5 ? "T" : "Tie"}>{team.f5}</li>
                          </ul>
                        </td>
                        {/* Render other data accordingly */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">Loading...</td>
                      <td colSpan="3"></td>
<td className="mobilenone"></td>
<td className="mobilenone"></td>
<td className="mobilenone"></td>
<td className="mobilenone"></td>


                    
                      
                    </tr>
                  )}
                </tbody>
</table>

      </div>
  </div>
    </section>
  )
}
